@import url("https://fonts.googleapis.com/css?family=Roboto");
* {
  box-sizing: border-box;
}

body {
  background-image: linear-gradient(to right, #a1c4fd, #c2e9fb);
}

html,
body,
div,
h1,
p {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}

.sub-title {
    overflow-y: scroll;
}

.element-card {
  position: relative;
  width: 350px;
  height: 300px;
  margin: 20px;
  transform-style: preserve-3d;
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border: 2px solid #e0e0d1;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 18px;
  cursor: pointer;
}
.element-card.open {
  width: 350px;
  height: 350px;
  transform: rotatey(-180deg) translatex(0px) translatey(0px);
}
.element-card .front-facing {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(0deg) translateZ(2px);
  padding-top: 10px;
  overflow: hidden;
  text-align: center;
}
.element-card .front-facing img {
  width: 70%;
  height: 70%;
}
.element-card .front-facing p {
  width: 80%;
  padding-top: 20px;
  text-align: center;
}
.element-card .back-facing {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotateY(180deg) translateZ(0px);
  height: 100%;
  justify-content: center;
}
.element-card .back-facing .answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.element-card .back-facing .answer .title {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4em;
}
.element-card .back-facing .answer .sub-title {
  color: #7d97ad;
  padding-bottom: 20px;
  font-weight: 600;
}
.element-card .back-facing button {
  width: 100px;
  height: 40px;
}

.btn-hover {
  font-size: 14px;
  font-weight: 200;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color {
  background-image: linear-gradient(to right, #667eea, #764ba2, #6b8dd6, #8e37d7);
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.inspire a {
  text-decoration: none;
}