/* Center the text field */
.text-field {
  display: block;
  margin: 0 auto;
}

/* Increase the size of the text field */
.text-field {
  font-size: 44px;
  width:100%;
    box-sizing: border-box; /* Include padding and border in the width calculation */

  /* You can adjust the font-size value as per your requirement */
}

/* Float the text field at the top of the page */
.text-field {
  top: 0;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  /* The above three properties will center the element horizontally on the page */
  /* If you want it to be centered horizontally and vertically, you can use 'top: 50%; transform: translate(-50%, -50%);' instead */
}

.input-container {
  text-align: center; /* Optional: To center the elements horizontally within the container */
}

.text-field, .save-button {
  display: inline-flex;
  vertical-align: middle; /* Optional: To vertically align the elements within the container */
}



.save-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  /* Add any additional styles for the save button */
}
